import React, { useEffect, useContext, /*useState, useRef, /*useCallback*/ } from 'react'
import MODAL from "../../constants/modal"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { caseRef, newTagsRef } from "../../firebase/index"
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons'
import MainTemplate from '../templates/MainTemplate'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { commonTheme } from "../../materialui/theme"
// import { vimeo } from "../../config/vimeo"
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as shareCaseActions } from "../../hooks/useShereCaseReducer"
import { actions as commonActions } from "../../hooks/useCommonReducer"
import CustomModal from "../common/CustomModal"
import { Back } from '../common/Back'
import { Required } from '../common/Required'
import ProgressModal from '../common/ProgressModal'
import MsgVideoLoading from '../common/MsgVideoLoading'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'unset',
  },
  movie: {
    display: 'flex',
    alignItems: 'center',
  },
  movieMediaWrap: {
    display: 'flex',
    margin: '10px 0',
    minHeight: '60px',
  },
  movieMedia: {
    maxWidth: '100px',
    maxHeight: '60px',
    margin: '0 15px 0 0',
  },
  form: {
    margin: '10px 0',
    width: '100%',
  },
  formTag: {
    marginRight: '20px',
  },
  fromChecked: {
    marginBottom: '0',
  },
  fromNewTag: {
    marginTop: '0',
  },
  formCompany: {
    borderBottom: 'solid 1px hsl(0, 0%, 80%)'
  },
  tagWrap: {
    alignItems: 'flex-end',
    margin: '25px 0px',
  },
  tagLabel: {
    margin: '0 0 4px',
    fontSize: '0.8em',
  },
  tagLabelCompany: {
    marginBottom: '13px',
  },
  tagValue: {
    margin: '-4px 0 0',
    fontSize: '1.2em',
  },
  caution: {
    margin: '5px 0',
    color: commonTheme.palette.error.main,
    fontSize: '0.9em',
  },
  submit: {
    margin: '10px',
  },
  progress: {
    borderRadius: '5px',
    position: 'absolute',
    background: '#fff',
    padding: '20px 20%',
    top: '0',
    left: '0',
    opacity: '0.9',
  },
}))

const PostCase = React.memo((props) => {
  const { key } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const { state: { shareCase, loginUser, common, admin }, dispatch } = useContext(GlobalContext)
  const { isLoading, disabled, errors } = common
  const { user } = loginUser
  const { doNotify } = admin
  const { cases, input, isChecked, industryTagList, memberNumTagList, prefTagList, otherTagList } = shareCase
  const { text, industryTags, memberNumTags, prefTags, otherTags, video } = input

  // const [doneUpload, /*setDoneUpload*/] = useState(false)
  // const doneUploadRef = useRef(doneUpload)

  // 既に選択されているtag
  const defaultIndustryTags = industryTags || ''
  const defaultPrefTags = prefTags ? prefTags.map(tag => prefTagList[tag.id - 1]) : ''
  const defaultMemberNumTags = memberNumTags
  const defaultOtherTags = otherTags || ''

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    return () => {
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'SHARE_CASE_POST_RESET' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin])

  // input監視
  useEffect(() => {
    const unallowed = (
      isLoading || !text || industryTags.length <= 0
      || memberNumTags.length <= 0 || prefTags.length <= 0
    )
    if (unallowed) {
      // ボタン無効
      !disabled && dispatch({ type: 'CHANGE_DISABLED', data: true })
    } else {
      // ボタン有効
      disabled && dispatch({ type: 'CHANGE_DISABLED', data: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, industryTags, memberNumTags, prefTags, isLoading, dispatch, disabled])

  useEffect(() => {
    // editの場合、投稿内容取得
    if (loginUser.isLogin && key) {
      if (cases.length <= 0) {
        caseRef.child(key)
          .once('value', (snapshot) => {
            const setEditCase = snapshot.val()
            if (!setEditCase) { return history.push('/') }
            if (!!setEditCase.newTags) { delete setEditCase.newTags }
            dispatch({ type: 'SET_EDIT_CASE', data: setEditCase })
          })
      } else {
        const setData = cases.find(c => c.key === key)
        if (!!setData.newTags) { delete setData.newTags }
        dispatch({ type: 'SET_EDIT_CASE', data: setData })
      }
    }
    // 会社が保有するタグをセットする
    if (loginUser.isLogin && !key) {
      dispatch({
        type: 'SET_EDIT_CASE', data: {
          ...input,
          industryTags: user.companyInfo.industryTags,
          prefTags: user.companyInfo.prefTags,
          memberNumTags: user.companyInfo.memberNumTags,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, key, loginUser.isLogin])

  // useEffect(() => {
  //   if (doneUpload) stop()
  //   return () => stop()
  // }, [doneUpload])

  useEffect(() => {
    if (errors.isError) {
      dispatch({
        type: 'IS_OPEN',
        data: { bool: true, stage: MODAL.error, path: '' }
      })
    }
    if (!errors.isError) {
      dispatch({ type: 'IS_CLOSE' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.isError])

  const handlePost = async () => {
    console.log('handlePost start')
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')

    // TODO:不要なuser情報削除、更新時の挙動修正
    await caseRef.push({
      ...input,
      user: user,
      createAt: now,
    })

    if (!!input.newTags && input.newTags.length > 0) {
      newTagsRef.push({
        uid: user.uid,
        userId: user.userId,
        tag: input.newTags,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      })
    }

    // 事例投稿通知
    shareCaseActions.sendNotice(input, user, doNotify)

    dispatch({ type: 'COMMON_RESET' })
    dispatch({ type: 'SHARE_CASE_POST_RESET' })
    history.push('/')
  }

  const handleUpdate = () => {
    console.log('update start')
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    caseRef.child(key)
      .update({
        ...input,
        updateAt: now
      })
    if (!!input.newTags && input.newTags.length > 0) {
      newTagsRef.push({
        uid: user.uid,
        userId: user.userId,
        tag: input.newTags,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      })
    }
    history.push('/#case-' + key)
  }

  const handleUploadVimeo = (e) => {
    commonActions.uploadVimeo(dispatch, e, loginUser.user)
  }

  // const start = useCallback((uri) => {
  //   console.log('start checking', uri)
  //   if (doneUploadRef.current) return

  //   doneUploadRef.current = setInterval(() => {
  //     console.log('checking', uri)
  //     vimeo.request(uri + '?fields=upload.status', (error, body, status_code, headers) => {
  //       if (body.upload.status === 'complete') {
  //         setDoneUpload(true)
  //         console.log('finished uploading.')

  //         // サムネ取得
  //         vimeo.request({
  //           method: 'GET',
  //           path: uri,
  //         }, (error, body, status_code, headers) => {
  //           if (error) {
  //             console.log("error", error)
  //             dispatch({ type: 'ERROR', data: error.error })
  //           } else {
  //             if (process.env.NODE_ENV === "development") { console.log(body) }
  //             console.log(uri + ' get thumnail')
  //             dispatch({ type: 'SET_THUMBNAIL_URL', data: body.pictures.sizes[0].link })
  //           }
  //         })

  //       } else if (body.upload.status === 'in_progress') {
  //         console.log('uploading.')
  //       } else {
  //         console.log('error during uploading.')
  //         setDoneUpload(true)
  //       }

  //     })
  //   }, 30000)
  // }, [])

  // const stop = useCallback(() => {
  //   console.log('stop cheking')
  //   if (!doneUploadRef.current) return
  //   clearInterval(doneUploadRef.current)
  //   dispatch({ type: 'PROGRESS_RESET' })
  //   doneUploadRef.current = false
  // }, [])

  const handleDeleteVimeo = (uri, i) => {
    commonActions.deleteVimeo(dispatch, uri, i)
  }

  const MsgNotYetInput = ({ align }) => <React.Fragment>
    {
      disabled && <Typography
        variant='caption'
        color='error'
        component='p'
        align={align}
        style={{ fontWeight: 'bold' }}
      >
        必須項目を全て入力してください。
      </Typography>
    }
  </React.Fragment>

  const SubmitButton = ({ isShow }) => {
    const classes = useStyles()
    return <React.Fragment>
      <MsgVideoLoading align='center' isLoading={isLoading} />

      {!isShow && <MsgNotYetInput align='center' />}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          !key && <Button
            variant="contained"
            color="secondary"
            onClick={() => handlePost()}
            className={clsx(classes.submit)}
            disabled={disabled}
          >投稿する</Button>
        }
        {
          // edit
          key && <React.Fragment>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push('/#case-' + key)}
              className={clsx(classes.submit)}
            >戻る</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUpdate()}
              className={clsx(classes.submit)}
              disabled={disabled}
            >修正を保存する</Button>
          </React.Fragment>
        }
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          key && <IconButton
            edge="end"
            onClick={() => dispatch({
              type: 'IS_OPEN',
              data: { bool: true, stage: MODAL.willDelete, path: `cases/${key}`, video: video }
            })}
          >
            <DeleteForeverIcon fontSize='small' style={{ marginRight: '5px' }} />
            <Typography component="span" variant="subtitle2" color='error' align='center'>
              投稿を削除する
            </Typography>
          </IconButton>
        }
      </Grid>
    </React.Fragment>
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      {/* 戻るボタン */}
      <Back history={history} />

      <Card className={classes.card}>
        <CardContent style={{ position: 'relative' }}>

          <p>動画をアップロードする<Required /></p>
          {isLoading && <ProgressModal />}
          <div className={classes.movie}>
            <input type="file" accept="video/*" onChange={e => handleUploadVimeo(e)} style={{ marginLeft: '-20px!important' }} />
          </div>

          <div className={classes.movieMediaWrap}>
            {
              video && video.thumbnailUrls && video.thumbnailUrls.length > 0 && video.thumbnailUrls.map(
                (url, key) => <Grid item key={key} style={{ marginTop: '10px' }}>
                  <Badge
                    color="error"
                    badgeContent={<CloseIcon />}
                    onClick={() => handleDeleteVimeo(video.vimeoUrls[key], key)}
                  >
                    <CardMedia
                      className={classes.movieMedia}
                      component="img"
                      image={url}
                      key={key}
                    />
                  </Badge>
                </Grid>
              )
            }
          </div>

          <div>
            <MsgVideoLoading align='left' isLoading={isLoading} />

            <FormControl className={classes.form} fullWidth={true}>
              <TextField
                label={<React.Fragment>事例内容を入力する<Required /></React.Fragment>}
                value={text}
                onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e.target.value, field: 'text' })}
                className={""}
                variant="outlined"
                multiline
                rows={8}
              />
            </FormControl>

            {/* タグ */}
            <div className={classes.tagWrap}>
              <p>タグ</p>

              <FormGroup row={true}>
                <FormControl className={clsx(classes.form, classes.formTag, classes.formCompany)}>
                  <p className={clsx(classes.tagLabel, classes.tagLabelCompany)}>会社名</p>
                  <p className={classes.tagValue}>{!!user.companyInfo ? user.companyInfo.name : ''}</p>
                </FormControl>

                <FormControl className={clsx(classes.form, classes.formTag)}>
                  <p className={classes.tagLabel}>業界・業種<Required /></p>
                  <Select
                    className={classes.tag}
                    onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e, field: 'industryTags' })}
                    label="業界・業種"
                    isMulti
                    options={industryTagList}
                    name="industries"
                    value={defaultIndustryTags}
                  />
                </FormControl>

                <FormControl className={clsx(classes.form, classes.formTag)}>
                  <p className={classes.tagLabel}>所在地<Required /></p>
                  <Select
                    className={classes.tag}
                    onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e, field: 'prefTags' })}
                    label="所在地"
                    isMulti
                    options={prefTagList}
                    name="prefs"
                    value={defaultPrefTags}
                  />
                </FormControl>

                <FormControl className={clsx(classes.form, classes.formTag)}>
                  <p className={classes.tagLabel}>社員数<Required /></p>
                  <Select
                    className={classes.tag}
                    onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e, field: 'memberNumTags' })}
                    label="社員数"
                    options={memberNumTagList}
                    name="memberNum"
                    value={defaultMemberNumTags}
                  />
                </FormControl>

                {
                  otherTagList.length > 0 && <FormControl className={clsx(classes.form, classes.formTag)}>
                    <p className={classes.tagLabel}>その他</p>
                    <Select
                      className={classes.tag}
                      onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e, field: 'otherTags' })}
                      label="その他"
                      options={otherTagList}
                      name="otherTags"
                      value={defaultOtherTags}
                      isMulti
                    />
                  </FormControl>
                }

              </FormGroup>
            </div>
            <FormGroup>
              <FormControlLabel className={clsx(classes.form, classes.fromChecked)}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => dispatch({ type: 'IS_CHECKED', data: e.target.checked })}
                    name="addTag"
                    color="primary"
                  />
                }
                label="新しいタグをリクエスト"
              />
              {isChecked && (
                <FormControl className={clsx(classes.form, classes.fromNewTag)}>
                  <Typography variant='caption' color='error'>※入力後は、「Create」を押すか、PCの「Enterキー」を押してください。</Typography>
                  <CreatableSelect
                    isMulti
                    onChange={(e) => dispatch({ type: 'CHANGE_VALUE', data: e, field: 'newTags' })}
                    placeholder="追加したいタグを入力して、Enterを押す"
                  />
                  <p className={classes.caution}>※事務局で判断して追加させていただきます。</p>
                </FormControl>
              )}
            </FormGroup>
          </div>

          <SubmitButton isShow={isLoading} />

        </CardContent>
      </Card >

      {/* 戻るボタン */}
      <Back history={history} />
    </MainTemplate >
  )
})
export default PostCase
