import React, { useEffect, useContext } from 'react'
import MODAL from "../../constants/modal"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { infoRef } from "../../firebase/index"
import { makeStyles } from '@material-ui/core/styles'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  DeleteForever as DeleteForeverIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons'
import MainTemplate from '../templates/MainTemplate'
import Select from 'react-select'
import { commonTheme } from "../../materialui/theme"
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as infoActions } from "../../hooks/useInfoReducer"
import { actions as signupActions } from "../../hooks/useSignUpReducer"
import { actions as adminActions } from "../../hooks/useAdminReducer"
import CustomModal from "../common/CustomModal"
import { Back } from '../common/Back'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

// 投稿タイプ
const typeList = [
  { id: 1, value: 'info', label: '通常投稿' },
  { id: 2, value: 'fixedInfo', label: '固定投稿' },
]

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'unset',
    paddingTop: '40px',
  },
  form: {
    margin: '10px 0',
    width: '100%',
  },
  formTag: {
    marginRight: '20px',
  },
  photoFrom: {
    marginBottom: '30px'
  },
  photo: {
    maxWidth: '80px', marginRight: '20px'
  },
  tagWrap: {
    alignItems: 'flex-end',
    margin: '25px 0px',
  },
  tagLabel: {
    margin: '0 0 4px',
    fontSize: '0.8em',
  },
  tagValue: {
    margin: '-4px 0 0',
    fontSize: '1.2em',
  },
  caution: {
    margin: '5px 0',
    color: commonTheme.palette.error.main,
    fontSize: '0.9em',
  },
  submit: {
    margin: '10px',
  },
  importantTxt: {
    color: theme.palette.error.main,
  },
  importantInfoForm: {
    borderColor: 'rgb(204 204 204)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    outline: '0!important',
    boxSizing: 'border-box',
    padding: '7.5px 14px 14.5px',
  },
  radioLabel: {
    '& > .MuiFormControlLabel-label': {
      fontSize: '14px',
    }
  }
}))

const PostInfo = React.memo((props) => {
  const { infoKey } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const { state: { info, loginUser, admin }, dispatch } = useContext(GlobalContext)
  const { input } = info
  const { doNotify, importantInfoKey } = admin
  const { text, title, type, photos, photoFiles, willDeletePhotos } = input


  // 既に選択されているタイプ
  const defaultType = (type === 'fixedInfo') ?
    typeList.find(t => t.id === 2) :
    typeList.find(t => t.id === 1)

    // TODO: 表示する2箇所
    const _isImportant = (!!infoKey && importantInfoKey === infoKey)  ? 'true' : 'false'
    const [radioValue, setRadioValue] = React.useState(_isImportant)
    const [submitBtnTxt, setSubmitBtnTxt] = React.useState('')

    // 初回mount
    useEffect(() => {
      // ログインしていない場合、ログインページに遷移
      if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
      if (loginUser.isLogin) {
        if (infoKey) {
          // editの場合、投稿内容取得
          infoRef.child(infoKey)
          .once('value', (snapshot) => {
            const data = snapshot.val()
            if (!!data) {
              dispatch({ type: 'SET_EDIT_INFO', data: { ...data, key: infoKey } })
            }
          })

          setSubmitBtnTxt('修正を保存する')
        }

        if (!infoKey) setSubmitBtnTxt('お知らせを作成する')
      }
      return () => {
        dispatch({ type: 'COMMON_RESET' })
        dispatch({ type: 'RESET_INFO_INPUT' })
      }
    }, [dispatch, history, loginUser.isLogin, infoKey])

  // input監視
  // useEffect(() => {
  //   const unallowed = (
  //     isLoading || !text || !title || (consultTo && consultTo.length <= 0))
  //   if (unallowed) {
  //     // ボタン無効
  //     !disabled && dispatch({ type: 'CHANGE_DISABLED', data: true })
  //   } else {
  //     // ボタン有効
  //     disabled && dispatch({ type: 'CHANGE_DISABLED', data: false })
  //   }
  // }, [text, title, consultTo, isLoading, dispatch, disabled])

  const handlePost = async () => {
    console.log('handle Post start')

    let updataPhotos = []

    const setImage = (obj) => {
      console.log("set image obj", obj)
      updataPhotos = updataPhotos.concat([obj])
    }

    // storegeに画像を保存
    await Promise.all(photoFiles.map(async (photo) => {
      const obj = await infoActions.storeImage(dispatch, photo.imageFile, 'info')
      setImage(obj)
    }))

    // お知らせ保存
    const data = infoActions.setInfo(loginUser.user, {
      ...input,
      photos: input.photos.concat(updataPhotos),
    })

    const newInfoRef = await infoRef.push(data)
    const newInfoKey = newInfoRef.key

    // 重要な投稿更新
    if (input.type === 'fixedInfo') {
      if (radioValue === 'true') {　adminActions.changeImportantInfo(dispatch, { infoKey: newInfoKey }) }
    }

    // 通知
    infoActions.sendNotice(data, doNotify)

    dispatch({ type: 'COMMON_RESET' })
    dispatch({ type: 'RESET_INFO_INPUT' })
    history.push('/admin')
  }

  const handleUpdate = async () => {
    console.log('update start')
    let updataPhotos = []

    const setImage = (obj) => {
      console.log("set image obj", obj)
      updataPhotos = updataPhotos.concat([obj])
    }

    // storegeに新しい画像を保存
    await Promise.all(photoFiles.map(async (photo) => {
      const obj = await infoActions.storeImage(dispatch, photo.imageFile, 'info')
      setImage(obj)
    }))

    // storegeから画像を削除
    await Promise.all(willDeletePhotos.map(async (photo) => signupActions.deleteImage(`${photo.name}`)))

    const data = infoActions.setInfo(loginUser.user, {
      ...input,
      photos: input.photos.concat(updataPhotos),
    })
    await infoRef.child(infoKey).update(data)

    // 重要な投稿更新
    if (input.type === 'fixedInfo') {
      const isChangeTrue = radioValue === 'true' && importantInfoKey !== input.key
      const isChangeFalse = radioValue === 'false' && importantInfoKey === input.key
      if (isChangeTrue || isChangeFalse) {
        const change_key = isChangeFalse ? null : input.key
        adminActions.changeImportantInfo(dispatch, { infoKey: change_key })
      }
    }

    history.push(`/admin`)
  }

  const SubmitButton = () => {
    return <React.Fragment>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          !infoKey && <Button
            variant="contained"
            color="secondary"
            onClick={() => handlePost()}
            className={clsx(classes.submit)}
          // disabled={disabled}
          >{submitBtnTxt}</Button>
        }
        {/* edit */}
        {
          infoKey && <React.Fragment>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.goBack()}
              className={clsx(classes.submit)}
            >戻る</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUpdate()}
              className={clsx(classes.submit)}
            >{submitBtnTxt}</Button>
          </React.Fragment>
        }
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {
          infoKey && <IconButton
            edge="end"
            onClick={() => dispatch({
              type: 'IS_OPEN',
              data: { bool: true, stage: MODAL.willDelete, path: `info/${infoKey}` }
            })}
          >
            <DeleteForeverIcon fontSize='small' style={{ marginRight: '5px' }} />
            <Typography component="span" variant="subtitle2" color='error' align='center'>
              投稿を削除する
            </Typography>
          </IconButton>
        }
      </Grid>
    </React.Fragment>
  }

  const handleSetPreview = (event) => {
    const files = Object.entries(event.target.files).map(([key, value]) => value)
    if (files.length > 0) {
      let datas = []
      files.forEach(file => {
        let reader = new FileReader()
        reader.onload = (e) => {
          const data = [{ imageBase64: e.target.result, imageFile: file }]
          datas = datas.concat(data)
          dispatch({
            type: 'SET_PREVIEW_IMAGES',
            data: photoFiles ? photoFiles.concat(datas) : datas
          })
        }
        reader.readAsDataURL(file)
      })
    }
  }

  const handleDeletePhoto = (photo, i) => {
    let newData = photoFiles.slice()
    newData.splice(i, 1)
    dispatch({
      type: 'SET_PREVIEW_IMAGES',
      data: newData
    })
  }

  // すでにstorageに保存されている画像で削除するものを管理
  const handleSetDeletePhoto = (photo, i) => {
    let newData = photos.slice()
    const deleteData = newData.splice(i, 1)
    dispatch({
      type: 'SET_DELETE_PHOTOS',
      data: newData,
      deleteData: deleteData,
    })
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      {/* 戻るボタン */}
      <Back history={history} />

      <Card className={classes.card}>

        <CardContent>

          <FormControl className={clsx(classes.form, classes.formTag)}>
            <p className={classes.tagLabel}>投稿タイプ</p>
            <Select
              className={classes.tag}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              onChange={(e) => dispatch({ type: 'CHANGE_INFO_VALUE', data: e.value, field: 'type' })}
              label="投稿タイプ"
              options={typeList}
              name="consultTo"
              value={defaultType}
            />
          </FormControl>

          {
            // 固定投稿の場合、重要設定をする
            defaultType.value === 'fixedInfo' &&
              <FormControl className={clsx(classes.form, classes.formTag)}>
                <p className={classes.tagLabel}>
                  「重要な投稿」に設定する
                </p>

                <div className={classes.importantInfoForm}>
                  <RadioGroup
                    row
                    aria-label="importantInfoKey"
                    name="importantInfoKey"
                    className={classes.importantInfoRadio}
                    value={radioValue}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    <FormControlLabel
                      className={classes.radioLabel}
                      value={'true'}
                      control={<Radio size="small" />}
                      label="設定する"
                      />
                    <FormControlLabel
                      className={classes.radioLabel}
                      value={'false'}
                      control={<Radio size="small" />}
                      label="設定しない"
                    />
                  </RadioGroup>

                  <p className={classes.tagLabel}>
                    <span className={classes.importantTxt}>
                      ※「重要な投稿」は、固定投稿の中で1つのみ設定できます。
                    </span><br/>
                    {
                      (!!importantInfoKey && importantInfoKey !== input.key) && <React.Fragment>
                        ※現在、他の投稿で「重要な投稿」が設定されています。<br/>
                        ※「設定する」で{submitBtnTxt}と、「重要な投稿」が置き換わりますのでご注意ください。<br/>
                        現在設定されている「重要な投稿」は、
                        <Link to="/" target="_blank">こちらから確認できます。</Link>
                        <OpenInNewIcon fontSize="small" />
                      </React.Fragment>
                    }
                    {
                      (!!importantInfoKey && importantInfoKey === input.key) && <React.Fragment>
                        ※現在、この投稿が「重要な投稿」に設定されています。<br/>
                      </React.Fragment>
                    }
                  </p>
                </div>
              </FormControl>

          }

          <FormControl className={classes.form} fullWidth={true}>
            <TextField
              label='タイトル'
              value={title}
              onChange={(e) => dispatch({ type: 'CHANGE_INFO_VALUE', data: e.target.value, field: 'title' })}
              variant="outlined"
              required
            />
          </FormControl>

          <FormControl className={classes.form} fullWidth={true}>
            <TextField
              label='内容'
              value={text}
              onChange={(e) => dispatch({ type: 'CHANGE_INFO_VALUE', data: e.target.value, field: 'text' })}
              variant="outlined"
              multiline
              rows={8}
              required
            />
          </FormControl>

          <FormControl className={clsx(classes.form, classes.photoFrom)} fullWidth={true}>
            <input
              type="file"
              multiple
              onChange={(e) => handleSetPreview(e)}
            />
          </FormControl>
          {
            // すでに登録された画像
            photos && <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              {photos.map((photo, i) => (
                <Grid
                  item
                  key={i}
                  className={classes.photo}
                >
                  <Badge
                    color="error"
                    badgeContent={<CloseIcon />}
                    onClick={() => handleSetDeletePhoto(photo, i)}
                  >
                    <CardMedia
                      image={photo.url}
                      component="img"
                    />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          }
          {
            // 新しく追加される画像
            photoFiles && <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              style={{ marginTop: '20px' }}
            >
              {photoFiles.map((photo, i) => (
                <Grid
                  item
                  key={i}
                  className={classes.photo}
                >
                  <Badge
                    color="error"
                    badgeContent={<CloseIcon />}
                    onClick={() => handleDeletePhoto(photo, i)}
                  >
                    <CardMedia
                      image={photo.imageBase64}
                      component="img"
                    />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          }

          <SubmitButton />

        </CardContent>

      </Card >

      {/* 戻るボタン */}
      <Back history={history} />
    </MainTemplate >
  )
})
export default PostInfo
