import {
  functions,
  caseRef,
  caseCommentsRef,
  companiesRef,
  goodsRef,
  masterRef,
  newTagsRef,
  noticeRef,
} from "../firebase/index"

import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import SITEINFO from '../constants/siteInfo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  shareCase: {
    feedbackCaseTag: {},
    industryTagList: [],
    prefTagList: [],
    memberNumTagList: [],
    otherTagList: [],
    newTags: null, // {}

    vimeoStates: [],
    vimeoShow: false,
    cases: [
      // {
      //   user: {},
      //   video: {},
      //   text: '',
      //   vimeoUrls: [],
      //   thumbnailUrls: [],
      //   industryTags: [],
      //   prefTags: [],
      //   otherTags: [],
      //   memberNumTags: [],
      //   newTags: [],
      //   createAt: '',
      // }
    ],
    filterdCases: [],
    case: {},
    caseComments: [
      // {
      //   key: '',
      //   caseKey: '',
      //   user: {},
      //   text: '',
      //   createAt: '',
      // }
    ],
    commentsParCase: [],
    caseGoods: [
      // {
      //   "caseKey": '',
      //   "user": {},
      //   "type": 'case'
      //   "createdAt": ''
      // }
    ],
    caseAllGoods: [],
    goodCaseKeys: [],
    input: {
      user: {},
      video: {
        vimeoUrls: [],
        thumbnailUrls: [],
      },
      text: '',
      industryTags: [],
      prefTags: [],
      memberNumTags: [],
      otherTags: [],
      newTags: [],
      createAt: '',
    },
    inputComments: {
      // [caseKey]: text: '',
    },
    inputCommentVideos: {
      // [caseKey]: {
      //   video: {
      //    thumbnailUrls: [],
      //    vimeoUrls: [],
      //   }
      // },
    },
    // [caseKey]: {
    //   user: { },
    //   caseKey: '',
    //   text: '',
    //   createAt: '',
    //   video: {
    //    thumbnailUrls: [],
    //    vimeoUrls: [],
    //   }
    // },
    editComments: {
      // [caseKey]: {
      //   user: { },
      //   caseKey: '',
      //   text: '',
      //   createAt: '',
      // },
    },
    movie: { url: '' },
    vimeoUrls: [],
    thumbnailUrls: [],
    isChecked: false,
    isShowCmntField: {
      // [caseKey]: true
    },
    isShowCmnts: {
      // [caseKey]: true
    },
    isShowCmntAlls: {
      // [caseKey]: true
    },
    isShowMore: {
      // key: false
    },
    // 会社投稿一覧
    company: {},
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        ...initialState.shareCase
      }
    case 'CASE_RESET':
      if (process.env.NODE_ENV === 'development') console.log('CASE_RESET');
      return {
        ...initialState.shareCase
      }
    case 'COMMENT_RESET':
      return {
        ...state,
        inputComments: {
          ...state.inputComments,
          [action.field]: ''
        },
        inputCommentVideos: {
          ...state.inputCommentVideos,
          [action.field]: null
        },
      }
    case 'SHARE_CASE_POST_RESET':
      return {
        ...state,
        input: initialState.shareCase.input,
        movie: initialState.shareCase.movie,
        isChecked: initialState.shareCase.isChecked,
        vimeoUrls: initialState.shareCase.vimeoUrls,
        thumbnailUrls: initialState.shareCase.vimeoUrls,
      }
    case 'SET_VIMEO_SHOW':
      return {
        ...state,
        vimeoShow: action.data,
      }
    case 'SET_VIMEO_STATES':
      const exist = state.vimeoStates.find(st => st.videoId === action.field)
      if (!exist) {
        const vs = state.vimeoStates.slice()
        vs.push({videoId: action.field, mounted: action.data})
        return {
          ...state,
          vimeoStates: vs,
        }
      }
      return {
        ...state,
        vimeoStates: state.vimeoStates.map(st => {
          // video id
          if (action.field !== st.videoId) return st
          return {
            ...st,
            mounted: action.data,
          }
        }),
      }
    case 'SET_FEEDBACK_CASE_TAG':
      return {
        ...state,
        feedbackCaseTag: action.data,
      }
    case 'SET_INDUSTRIES':
      return {
        ...state,
        industryTagList: action.data,
      }
    case 'SET_MEMBERNUMS':
      return {
        ...state,
        memberNumTagList: action.data,
      }
    case 'SET_PREFS':
      return {
        ...state,
        prefTagList: action.data,
      }
    case 'CHANGE_OTHER_TAGS':
      return {
        ...state,
        otherTagList: action.data,
      }
    case 'CHANGE_NEW_TAGS':
      return {
        ...state,
        newTags: action.data,
      }

    case 'SET_CASE':
      return {
        ...state,
        case: action.data,
      }
    case 'SET_CASES':
      return {
        ...state,
        cases: action.data,
      }
    case 'SET_FILERED_CASES':
      return {
        ...state,
        filterdCases: action.data,
      }
    case 'SET_COMMENTS':
      return {
        ...state,
        caseComments: action.data,
      }
    case 'SET_COMMENTS_PAR_CASE':
      return {
        ...state,
        commentsParCase: action.data,
      }
    case 'SET_ALL_GOODS':
      return {
        ...state,
        caseAllGoods: action.data,
      }
    case 'SET_GOODS':
      const goodCaseKeys = action.data.map(good => good.caseKey)
      return {
        ...state,
        caseGoods: action.data,
        goodCaseKeys: goodCaseKeys,
      }
    case 'CHANGE_GOOD_CASE_KEYS':
      return {
        ...state,
        goodCaseKeys: action.data,
      }
    case 'SET_EDIT_CASE':
      return {
        ...state,
        input: action.data,
      }
    case 'CHANGE_VALUE':
      return {
        ...state,
        input: {
          ...state.input,
          [action.field]: action.data,
        }
      }
    case 'CHANGE_COMMENT_VALUE':
      // const disabled = action.data === '' ? true : false
      return {
        ...state,
        inputComments: {
          ...state.inputComments,
          [action.field]: action.data
        },
      }
    case 'CHANGE_EDIT_COMMENT_VALUE':
      return {
        ...state,
        editComments: {
          ...state.editComments,
          [action.field]: {
            ...state.editComments[action.field],
            text: action.data
          }
        },
      }
    case 'IS_SHOW_CMNT_FIELD':
      const bool = action.data
      const setComment = bool ? state.caseComments.filter(cmnt => cmnt.key === action.field)[0] : {}
      return {
        ...state,
        isShowCmntField: {
          ...state.isShowCmntField,
          [action.field]: bool
        },
        editComments: {
          [action.field]: setComment
        }
      }
    case 'IS_SHOW_CMNT':
      return {
        ...state,
        isShowCmnts: {
          ...state.isShowCmnts,
          [action.field]: action.data
        }
      }
    case 'IS_SHOW_CMNT_ALL':
      return {
        ...state,
        isShowCmntAlls: {
          ...state.isShowCmntAlls,
          [action.field]: action.data
        }
      }
    case 'SET_MOVIE':
      return {
        ...state,
        movie: {
          ...state.movie,
          [action.field]: action.data,
        }
      }
    case 'SET_VIMEO_URL':
      const updateUrls = [...state.input.video.vimeoUrls]
      updateUrls.push(action.data)
      return {
        ...state,
        input: {
          ...state.input,
          video: {
            ...state.input.video,
            vimeoUrls: updateUrls
          }
        }
      }
    case 'SET_CASE_COMMENT_VIMEO_URL':
      const video_data = state.inputCommentVideos[action.field]
      const is_first_video = !video_data || !video_data.video || !video_data.video.vimeoUrls
      const updateCaseCmntUrls = is_first_video ? [] : [...video_data.video.vimeoUrls]
      const caseCmntVideo = (!video_data || !video_data.video) ? {} : video_data.video
      updateCaseCmntUrls.push(action.data)
      return {
        ...state,
        inputCommentVideos: {
          ...state.inputCommentVideos,
          [action.field]: {
            video: {
              ...caseCmntVideo,
              vimeoUrls: updateCaseCmntUrls
            }
          }
        }
      }
    case 'SET_THUMBNAIL_URL':
      const updateThumUrls = [...state.input.video.thumbnailUrls]
      updateThumUrls.push(action.data)
      return {
        ...state,
        input: {
          ...state.input,
          video: {
            ...state.input.video,
            thumbnailUrls: updateThumUrls
          }
        }
      }
    case 'SET_CASE_COMMENT_THUMBNAIL_URL':
      const thumb_data = state.inputCommentVideos[action.field]
      const is_first_thumb = !thumb_data || !thumb_data.video || !thumb_data.video.thumbnailUrls
      const updateCaseCmntThumbUrls = is_first_thumb ? [] : [...thumb_data.video.thumbnailUrls]
      const caseCmntThumbVideo = (!thumb_data || !thumb_data.video) ? {} : thumb_data.video
      updateCaseCmntThumbUrls.push(action.data)
      return {
        ...state,
        inputCommentVideos: {
          ...state.inputCommentVideos,
          [action.field]: {
            video: {
              ...caseCmntThumbVideo,
              thumbnailUrls: updateCaseCmntThumbUrls
            }
          }
        }
      }
    case 'RESET_CASE_COMMENT_VIDEO':
      const data = state.inputCommentVideos[action.field]
      const caseCmntRestVideo = !data || !data.video ? {} : data.video
      return {
        ...state,
        inputCommentVideos: {
          ...state.inputCommentVideos,
          [action.field]: {
            video: {
              ...caseCmntRestVideo,
              thumbnailUrls: data.video.thumbnailUrls.filter((uri, i) => i !== action.num),
              vimeoUrls: data.video.vimeoUrls.filter((uri, i) => i !== action.num),
            }
          }
        }
      }
    case 'RESET_VIDEO':
      return {
        ...state,
        input: {
          ...state.input,
          video: {
            ...state.input.video,
            thumbnailUrls: state.input.video.thumbnailUrls.filter((uri, i) => i !== action.num),
            vimeoUrls: state.input.video.vimeoUrls.filter((uri, i) => i !== action.num),
          }
        }
      }
    case 'IS_CHECKED':
      return {
        ...state,
        isChecked: action.data
      }
    case 'CHANGE_SHOW_MARE':
      return {
        ...state,
        isShowMore: {
          ...state.isShowMore,
          [action.key]: action.data
        }
      }
    // 会社事例一覧
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.data
      }
    default: return state
  }
}

export const actions = {
  getMasterData: (dispatch) => {
    masterRef.child('feedbackCaseTag').once('value', (snapshot) => {
      const feedbackCaseTag = snapshot.val()
      if (feedbackCaseTag) { dispatch({ type: 'SET_FEEDBACK_CASE_TAG', data: feedbackCaseTag }) }
    })
  },
  getIndustries: (dispatch) => {
    masterRef.child('industries').once('value', (snapshot) => {
      const industies = snapshot.val()
      if (industies) { dispatch({ type: 'SET_INDUSTRIES', data: industies }) }
    })
  },
  getMemberNums: (dispatch) => {
    masterRef.child('memberNums').once('value', (snapshot) => {
      const memberNums = snapshot.val()
      if (memberNums) { dispatch({ type: 'SET_MEMBERNUMS', data: memberNums }) }
    })
  },
  getPrefs: (dispatch) => {
    masterRef.child('prefs').once('value', (snapshot) => {
      const prefs = snapshot.val()
      if (prefs) { dispatch({ type: 'SET_PREFS', data: prefs }) }
    })
  },
  getOtherTags: (dispatch) => {
    masterRef
      .child('otherTags')
      .once('value', (snapshot) => {
        const otherTags = snapshot.val()
        if (otherTags) dispatch({ type: 'CHANGE_OTHER_TAGS', data: otherTags })
      })
  },
  getNewTags: (dispatch) => {
    newTagsRef
      .once('value', (snapshot) => {
        dispatch({ type: 'CHANGE_NEW_TAGS', data: snapshot.val() })
      })
  },
  getCase: (dispatch, key) => {
    console.log("get case")
    caseRef.child(key)
      .once('value', (snapshot) => {
        dispatch({ type: 'SET_CASE', data: snapshot.val() })
      })
  },
  getCases: (dispatch, companyId = null) => {
    const ref = companyId 
      ? caseRef.orderByChild("user/companyId").equalTo(companyId)
      : caseRef.orderByKey();

      ref.on('value', (snapshot) => {
        console.log("get case", companyId)
        const cases = snapshot.val()
        if (cases === null) { return dispatch({ type: 'SET_CASES', data: [] }) }
        const entries = Object.entries(cases).reverse()
        const newCases = entries.map((entry) => {
          const [key, content] = entry
          return { key, ...content }
        })
        dispatch({ type: 'SET_CASES', data: newCases })
      })
  },
  changeGood: (dispatch, props) => {
    console.log("changeGood")
    const { caseGoods, goodCaseKeys, caseKey, user } = props

    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const copyArr = goodCaseKeys.slice()
    const isAdd = copyArr.includes(caseKey) ? false : true

    if (isAdd) {
      goodsRef.push({
        user: user,
        caseKey: caseKey,
        createdAt: now,
        type: 'case',
      })
      dispatch({ type: 'CHANGE_GOOD_CASE_KEYS', data: copyArr.concat(caseKey) })
    } else {
      const goods = caseGoods.filter(good => good.caseKey === caseKey)
      goods.map(g => (
        // 削除する
        goodsRef.child(g.key).remove()
          .then(() => {
            const index = copyArr.indexOf(caseKey)
            copyArr.splice(index, 1)
            dispatch({ type: 'CHANGE_GOOD_CASE_KEYS', data: copyArr })
          })
      ))
    }
  },
  getGoods: (dispatch, uid) => {
    goodsRef
      .orderByKey()
      .on('value', (snapshot) => {
        console.log("get good")
        const goods = snapshot.val()
        if (goods === null) { return dispatch({ type: 'SET_GOODS', data: [] }) }
        const entries = Object.entries(goods).reverse()
        const newAllGoods = entries.map((entry) => {
          const [key, content] = entry
          return { key, ...content }
        })
        const newGoods = newAllGoods.filter(g => g.user.uid === uid)
        dispatch({ type: 'SET_ALL_GOODS', data: newAllGoods })
        dispatch({ type: 'SET_GOODS', data: newGoods })
      })
  },
  getComments: (dispatch) => {
    caseCommentsRef
      .orderByChild('createdAt')
      .on('value', (snapshot) => {
        console.log("get comment")
        const comments = snapshot.val()
        if (comments === null) { return dispatch({ type: 'SET_COMMENTS', data: [] }) }
        const entries = Object.entries(comments)
        const newComments = entries.map((entry) => {
          const [key, content] = entry
          return { key, ...content }
        })
        dispatch({ type: 'SET_COMMENTS', data: newComments })
      })
  },
  moutedGetData: (dispatch, uid, companyId = null) => {
    actions.getCases(dispatch, companyId)
    actions.getComments(dispatch, companyId)
    actions.getGoods(dispatch, uid, companyId)
    actions.getMasterData(dispatch)
    actions.getIndustries(dispatch)
    actions.getMemberNums(dispatch)
    actions.getPrefs(dispatch)
    actions.getOtherTags(dispatch)
  },
  updateComment: (dispatch, comment, editComments) => {
    console.log("UpdateComment")
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const newCmnt = editComments[comment.key]
    caseCommentsRef.child(comment.key)
      .update({
        ...newCmnt,
        updateAt: now
      })
    dispatch({ type: 'IS_SHOW_CMNT_FIELD', field: comment.key, data: false })
  },

  postComment: (dispatch, props) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const { text, caseKey, user, video, isUploadVideo } = props
    const isVideo = isUploadVideo &&
                    video && video.video &&
                    video.video.vimeoUrls &&
                    video.video.vimeoUrls.length > 0 &&
                    video.video.thumbnailUrls &&
                    video.video.thumbnailUrls.length > 0
    const videoData = !isVideo ? {} : { video: video.video }

    caseCommentsRef.push({
      text: text,
      user: user,
      caseKey: caseKey,
      createdAt: now,
      ...videoData,
    })

    // NOTE: パジャポス権限が動画をアップロードした場合、フィードバックタグを付与
    if (isVideo && user.pajaposs) {
      caseRef.child(caseKey)
        .update({
          isFeedback: true,
          updateAt: now
        })
    }

    console.log('COMMENT_RESET');
    dispatch({ type: 'COMMENT_RESET', field: caseKey })
  },

  getCompany: (dispatch, companyId) => {
    companiesRef
      .child(companyId)
      .on('value', (snapshot) => {
        console.log("get company")
        const company = snapshot.val()
        dispatch({ type: 'SET_COMPANY', data: !!company ? company : {} })
      })
  },
  sendNotice: async (input, user, doNotify) => {
    if (!doNotify) return
    const msg = `
事例が共有されました。
==========================================
${user.companyInfo.name}
${input.text}
${SITEINFO.appUrl}
`
    let notices = {}
    const setNotices = async (data) => { return notices = { ...notices, ...data } }
    await setNotices((await noticeRef.child('all').once('value')).val())
    await setNotices((await noticeRef.child('caseAll').once('value')).val())
    await Promise.all(input.industryTags.map(async tag => {
      const path = 'case' + tag.value.charAt(0).toUpperCase() + tag.value.slice(1)
      setNotices((await noticeRef.child(path).once('value')).val())
    }))

    console.log("notices", notices)

    // 重複を削除
    const arr = Object.entries(notices).map(([key, value]) => ({ [key]: value }))
    const result = arr.filter((element, index, self) => self.findIndex(e => e.userId === element.userId) === index)
    const obj = {}
    result.forEach((data, i) => { Object.entries(data).map(([key, value]) => obj[key] = value) })

    const func = functions.httpsCallable("notice")
    func({ notices: obj, msg: msg })
      .then(res => {
        console.log(res);
      }).catch(e => {
        console.log(e);
      })
  },
  filterCases: (dispatch, searchTag, cases, tags) => {
    const {
      industryTagList,
      memberNumTagList,
      prefTagList,
      feedbackCaseTag,
      otherTagList,
    } = tags

    const selectTag = () => {
      if (!searchTag) return null;
    
      const isFeedbackTag = (feedbackCaseTag.id === searchTag.id) &&
                            (feedbackCaseTag.label === searchTag.label) &&
                            (feedbackCaseTag.value === searchTag.value);
      
      if (isFeedbackTag) {
        return { type: 'feedback', id: searchTag.id };
      }
    
      const member = memberNumTagList.find((tag) => 
        (tag.id === searchTag.id) &&
        (tag.label === searchTag.label) &&
        (tag.value === searchTag.value)
      );
      if (member) {
        return { type: 'member', id: member.id };
      }
    
      const industry = industryTagList.find((tag) => 
        (tag.id === searchTag.id) &&
        (tag.label === searchTag.label) &&
        (tag.value === searchTag.value)
      );
      if (industry) {
        return { type: 'industry', id: industry.id };
      }
    
      const pref = prefTagList.find((tag) => 
        (tag.id === searchTag.id) &&
        (tag.label === searchTag.label) &&
        (tag.value === searchTag.value)
      );
      if (pref) {
        return { type: 'pref', id: pref.id };
      }
    
      const other = otherTagList.find((tag) => 
        (tag.id === searchTag.id) &&
        (tag.label === searchTag.label) &&
        (tag.value === searchTag.value)
      );
      if (other) {
        return { type: 'other', id: other.id };
      }
    
      return false; // 登録のない単語
    };

    const selectedTag = selectTag()
    if (selectedTag === false) return dispatch({ type: 'SET_FILERED_CASES', data: [] })
    if (!selectedTag) return dispatch({ type: 'SET_FILERED_CASES', data: cases })


    let data = []
    const setFeedbackCases = () => cases.filter(c => c.isFeedback); // 名前付き関数
    const setCases = (type) => cases.filter(c => c[type]?.some(tag => tag.id === selectedTag.id)); // 名前付き関数
    const setMemberCases = (type) => cases.filter(c => c[type].id === selectedTag.id); // 名前付き関数

    // 下記のようにフィルタリングの処理も名前付き関数を活用
    switch (selectedTag.type) {
      case 'feedback':
        data = setFeedbackCases();
        break;
      case 'other':
        data = setCases('otherTags');
        break;
      case 'industry':
        data = setCases('industryTags');
        break;
      case 'member':
        data = setMemberCases('memberNumTags');
        break;
      case 'pref':
        data = setCases('prefTags');
        break;
      default:
        break;
    }
    dispatch({ type: 'SET_FILERED_CASES', data })
  },
}
